<template>
  <div>
    <p>为了帮助你回顾你在有鱼记账的使用情况，有鱼记账将根据你的授权对你在有鱼记账的使用情况进行查询并汇总分析形成你的有鱼记账账单。
      在你正式开始《有鱼记账之旅》之前应仔细阅读并充分理解本协议中的全部内容，如你不同意本协议中的任何条款，请立即停止使用该活动。
      你开启有鱼记账之旅的行为将被视为已经仔细阅读，充分理解并接受协议所有条款。</p>
    <p>1.为了生成你的有鱼记账之旅报告，有鱼记账将根据该授权查询你在有鱼记账自注册之日至2022年11月30日期间的相关用户信息，
      包括使用情况以及使用记录等（含注册时间，记账次数，记账时间，收支记录，消费支出类型，共享账本以及共享人等数据情况）进行查询并汇总分析。
      若你未登录，请确认你有权授权有鱼记账对当前设备项下的信息以及使用情况进行查询并汇总分析。</p>
    <p>2.你的账单数据为系统自动生成的结果，可能与实际情况有偏差，有鱼记账不对数据的准确性，有效性进行保证。</p>
    <p>3.本次活动各页面会包含你的个人信息，当你选择向其他渠道转发活动页面的相关信息以及截图等（你可以通过保存图片，
      一键分享等方式将你的有鱼记账之旅的账单内容分享至微信好友以及微信朋友圈，将生成的结果分享给你的家人和朋友。），
      其他人可通过你展示的渠道看到你公开的相关信息。如你因分享，公开你的年度报告而产生的任何损失（包括但不限于个人信息泄露等）应由你自行承担，故请你谨慎选择分享相关内容。</p>
    <p>4.你的有鱼记账之旅，为通过系统生成的结果，非官方实际内容，仅供娱乐。</p>
    <p>5.其他未尽事宜，仍受《有鱼记账隐私政策》《用户服务协议》等其他相关文件约束。</p>
    <p>6.如你对有鱼记账之旅活动以及服务有任何疑问或意见，可通过有鱼记账客服联系我们，以便我们为你解释和说明。</p>
  </div>
</template>
<script>
export default {
  name: 'StatementRule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
