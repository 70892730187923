<template>
  <div class="annual-page">
    <div class="head">
      <img
      src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208151856532-head-title.png" alt="">
    </div>
    <div class="module-shark">
      <div class="shark" :class="{ animate: isAnimate }">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208152600164-fish.png" alt="">
      </div>
    </div>
    <div class="module-bottom">
      <div class="btn" @click="jumpAction">开启精彩旅程</div>
      <div class="content">
        <div class="check-btn" :class="{ checked: isChecked }"
          @click="(isChecked = !isChecked)">
        </div>
        <div class="desc">
          <p>同意使用你的有鱼记账信息生成有鱼记账之旅</p>
          <p>详情见<span class="active" @click="ruleVisible = true">《信息授权协议》</span></p>
          <p>统计数据时间截止到<span class="bold">2022年11月30日</span></p>
        </div>
      </div>
    </div>
    <div class="module-footer" :class="{ animate: isAnimate }">
      <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208153559921-people.png" alt="">
    </div>
     <!----活动规则--->
    <y-actionsheet :is-visible="ruleVisible"
      :isLockBgScroll="true"
      @close="ruleVisible = false">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
  </div>
</template>
<script>
import { getAppStatus } from '@/utils/app';
import YActionsheet from '@/components/actionsheet';
import rule from './components/rule';

export default {
  name: 'AnnualStatement',
  components: {
    YActionsheet,
    rule,
  },
  data() {
    return {
      isChecked: true,
      ruleVisible: false,
      isAnimate: true,
    };
  },
  computed: {
    toastStyle() {
      return {
        backgroundColor: 'rgba(34, 33, 38, .5)',
      };
    },
    cuserId() {
      return getAppStatus().cuserId;
      // return '28fdeefa-c820-4c8d-a7bd-6163eb0aab35';
    },
    activityId() {
      return 6;
    },
    businessType() { // 活动标识
      return 0;
    },
  },
  mounted() {
    document.title = '有鱼记账之旅';
  },
  created() {
    this.cuserId && this.blindMonitor();
  },
  methods: {
    jumpAction() {
      if (!this.isChecked) {
        return this.$toast({
          content: '请勾选并悉知授权后，开启精彩旅程',
          style: this.toastStyle,
        });
      }
      window?._monitor('track', 'kqjclcan_click', {
        eventName: '开启精彩旅程按钮点击',
      });
      this.$router.push('/activity/annual/slider');
    },
    blindMonitor() {
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      });
      window._monitor('pageset', 'ndzdsy_imp', {
        eventName: '年度账单首页曝光',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/animate.scss';
@import './styles/home.scss';
</style>
